/* eslint-env node */
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'

async function sha256(str) {
  // Convert string to ArrayBuffer
  const buff = Uint8Array.from(str, c => c.charCodeAt(0)).buffer
  // Calculate digest
  const digest = await crypto.subtle.digest('SHA-256', buff)
  // Convert ArrayBuffer to hex string
  // (from: https://stackoverflow.com/a/40031979)
  return [].map.call(new Uint8Array(digest), x => x.toString(16).padStart(2, '0')).join('')
}

const createClient = uri => {
  if (process.env.NODE_ENV !== 'production' && uri === 'mock') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require('./create-mock-client').default()
  }
  const retry = new RetryLink({ delay: { initial: 1000 } })
  const http = new HttpLink({ uri })
  const link = createPersistedQueryLink({ sha256 }).concat(retry).concat(http)

  return new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })
}

export default createClient
