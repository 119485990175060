import { createMuiTheme } from '@material-ui/core/styles'

import { colorToHex } from '@karakuri-ai/common'

import * as styles from '../styles/variables.module.scss'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colorToHex(styles.mainColor),
    },
    secondary: {
      main: colorToHex(styles.secondaryColor),
    },
  },
  props: {
    MuiButton: {
      color: 'inherit',
      style: {
        textTransform: 'none',
      },
      disableRipple: styles.disableRipple === 'true',
    },
    MuiList: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  typography: {
    // useNextVariants: true
  },
})

export default theme
